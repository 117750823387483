import PageUnderConstruction from "../../shared/PageUnderConstruction/PageUnderConstruction";

const Feed = props => {
  return (
    <>
      <div id='feedModeTab' className='mode-page' role='tabpanel' aria-label='Feed Tab' tabIndex='0'>
        <PageUnderConstruction heading={props.heading} />
        <button id='feedModeActionBtn' type='button' className='float-btn'>
          <span className='fas fa-comment-medical fa-fw' aria-hidden='true'></span>
          New Post
        </button>
      </div>
    </>
  );
};

export default Feed;
