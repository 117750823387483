import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useUserContext } from "./components/contexts/UserContext";
import { useUserContext } from "./components/contexts/UserContext";
import Tabs from "./features/shared/Tabs/Tabs";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import logo from './images/sslogo_sm.png';
import DefaultProfilePic from './images/DefaultProfilePic.jpg';
import { exportNullUser } from "./services/userAuthenticationServices";
// import { useAppContext } from "./components/contexts/AppContext";
import About from "./features/about/pages";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useDispatch } from "react-redux";
import { logoutUser, setUser } from "./features/user/userSlice";
import { useSelector } from "react-redux";
import { enableShowPane } from "./features/about/aboutSlice";
/**
 * 
 * * The idea of root layout is to create a seperate copy of the navigation bar that has config options
 */
const RootLayout = () => {

  const user = useSelector(state => state.user.user);
  const userAuthenticated = useSelector(state => state.user.authenticated);
  const dispatch = useDispatch();
  const [menuOpened, setMenuOpened] = useState(false);
  const navigate = useNavigate();  
  const menuBtnClickHandler = (event) => {
    event.stopPropagation();
    setMenuOpened(!menuOpened);
  };

  const profileButtonClickHandler = () => {
    navigate('/manageAccount');
  }

  const logoutClickHandler = () => {
    // setUser(exportNullUser());
    // dispatch(setUser(logoutUser()));
    dispatch(logoutUser());
  }

  const handleLogoClick = () => {
    navigate('/feed'); 
  };

  // const aboutClickHandler = () => setShowAboutPane(true);
  const aboutClickHandler = () => dispatch(enableShowPane(true));

  useEffect(() => {
    const handleClick = (event) => {
      setMenuOpened(false);
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    }
  }, []);


  useEffect(() => {
    console.log('Root layout use effect executing :', userAuthenticated);
    if (!userAuthenticated) navigate("/login");
  }, [userAuthenticated]);

  const renderUserTooltip = (props) => (
    <Tooltip id="user-tooltip" {...props} className="d-flex flex-column">
      {user.personalInfo.firstName} {user.personalInfo.lastName}
      <br />
      Click to open account & profile settings
    </Tooltip>
  )

  return (
    <>
      <About />
      <header className='navbar'>
        <a id='sLink' className='skip-link' tabIndex='0'>
          Skip to content
        </a>
        <button
          id='menuBtn'
          type='button'
          className={`navbar-btn ${userAuthenticated ? "" : "hidden"}`}
          title='Menu'
          aria-controls='sideMenu'
          aria-label='Actions'
          aria-haspopup='true'
          aria-expanded='false'
          onClick={menuBtnClickHandler}
        >
          {/* <span id="menuBtnIcon" className="navbar-btn-icon fas fa-bars"></span> */}
          <span id='menuBtnIcon' className='navbar-btn-icon fas'>
            <FontAwesomeIcon icon={`${menuOpened ? "times" : "bars"}`} />
          </span>
        </button>
        <img src={logo} className='navbar-app-icon' alt='SpeedScore logo' onClick={handleLogoClick} />
        <h1 id='appName' className='navbar-title' onClick={handleLogoClick}>
          SpeedScore
        </h1>
        <div className='navbar-right-items'>
          {/* <div id="coursesModeSearchDiv" className="navbar-right-items hidden"> -->
        For React coursesModeSearch component 
      </div>  */}
          <OverlayTrigger
            overlay={renderUserTooltip}
            placement="left"
            delay={{ show: 150, hide: 300 }}
          >
            <button
              id='profileBtn'
              type='button'
              className={`navbar-btn navbar-profile-btn ${userAuthenticated ? "" : "hidden"}`}
              aria-label='Account and Profile Settings'
              // style={{backgroundImage: `url(${user.identityInfo.profilePic ? user.identityInfo.profilePic : DefaultProfilePic})`}}
              style={{backgroundImage: `url(${userAuthenticated && user.personalInfo.profilePic ? user.personalInfo.profilePic : DefaultProfilePic})`}}
              onClick={profileButtonClickHandler}
            />
          </OverlayTrigger>
        </div>
        {/* <!--THE SIDE MENU --> */}
        <ul
          id='sideMenu'
          role='menu'
          className={`sidemenu ${userAuthenticated ? "" : "hidden"} ${menuOpened ? "sidemenu-open" : ""}`}
          arial-labelledby='menuBtn'
        >
          <li role='menuitem' tabIndex='-1' onClick={profileButtonClickHandler}>
            Account & Profile
          </li>
          <li role='menuitem' tabIndex='-1' onClick={aboutClickHandler}>
            About
          </li>
          <li role='menuitem' tabIndex='-1' onClick={logoutClickHandler}>
            Log Out
          </li>
        </ul>
      </header>
      <main>
        <Tabs />
        <Outlet />
      </main>
    </>
  );
};

export default RootLayout;
