export const MILE_IN_FEET = 5280;
export const MILE_IN_KM = 1.609;

export const validationErrorMap = {
    roundDate: "Please enter a valid date",
    roundCourse: "Please enter a course name having at most 50 characters",
    roundStrokes: "Please enter strokes as an integer value between 9 and 200",
    roundMinutes: "Please enter minutes as an integer value between 10 and 400",
    roundSeconds: "Please enter seconds as an integer value between 0 and 60",
    roundNotes: "Please enter notes having at most 500 characters",
    roundDistance: "Please enter a distance value between 0.10 and 62 miles (100 km)"
}