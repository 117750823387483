import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ModalDialog = ({ title, body, actionBtnText, cancelBtnText = "Close", isOpen, close, onSubmit }) => {
  return (
    <Modal show={isOpen} onHide={close} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          {cancelBtnText}
        </Button>
        {actionBtnText && (
          <Button variant="danger" onClick={() => {
            onSubmit();
            close();
          }}>
            {actionBtnText}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default ModalDialog;
