import { Bounce, toast } from "react-toastify";



export const notifyMessage = (notifyMode, message, time, theme, position) => {
  toast[notifyMode](<div style={{ whiteSpace: "pre-line" }}>{message}</div>, {
    position: position,
    autoClose: time, // takes time in milli seconds
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: theme,
    transition: Bounce
  });
  return;
}