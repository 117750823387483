
// TODO: duplicate code, need to refactor later
// function convertFeetToYards(feet) {
//   const yards = feet / 3;
//   return Math.floor(yards);
// }

import { convertFeetToKilometers, convertFeetToMiles } from "../utils/utils";

// function convertFeetToMeters(feet) {
//   const meters = feet * 0.3048;
//   return Math.floor(meters);
// }

// export function convertFeetToMiles(feet) {
//   const miles = feet / 5280;
//   return miles.toFixed(2).toString() + " mi";
// }

// export function convertFeetToKilometers(feet) {
//   const kilometers = feet * 0.0003048;
//   return kilometers.toFixed(2).toString() + " km";
// }

// export const paceCalculator = (minutes, seconds, distance, distanceUnit = 'km') => {
//   try {
//     if (!minutes || (!seconds && seconds != 0) || !distanceUnit) return null;
//     if (distance === 0) return null;
//     const totalMinutes = parseInt(minutes) + parseInt(seconds) / 60;
  
//     // distance = convertFeetToKilometers(distance)

//     const distanceInMiles = distanceUnit === "km" ? distance * 0.621371 : distance;
  
//     const pace = totalMinutes / parseFloat(distanceInMiles);
  
//     const paceMinutes = Math.floor(pace);
//     const paceSeconds = Math.round((pace - paceMinutes) * 60);
  
//     const unitLabel = distanceUnit === "km" ? "Min/Km" : "Min/Mile";
  
//     return `${paceMinutes}:${paceSeconds.toString().padStart(2, "0")} ${unitLabel}`;
//   } catch (e) {
//     return null;
//   }
// };

export const paceCalculator = (pace, distanceUnit = 'km') => {
  if (!pace) return pace;
  const paceMinutes = Math.floor(pace);
  const paceSeconds = Math.round((pace - paceMinutes) * 60);
  const unitLabel = distanceUnit === "km" ? "Min/Km" : "Min/Mile";
  return `${paceMinutes}:${paceSeconds.toString().padStart(2, "0")} ${unitLabel}`;
}

export const distanceCalculator = (distance, distanceUnit) => {
  let output = null;
  if (typeof distance === 'number' && !isNaN(distance)) {
    if (distanceUnit === "km") {
      output = convertFeetToKilometers(distance);
    } else if (distanceUnit) {
      output = convertFeetToMiles(distance);
    }
    // kilometer function is already returning 2 decimal places
    if (typeof distance === 'number' && !isNaN(distance) && distance != 0) {
      // console.log('output', output)
      if ( distanceUnit !== 'km')
      output = output.toFixed(2);
    } else {
      output = "Null";
    }
  } else {
    output = "Null";
  }
  // console.log('output', output, distanceUnit, convertFeetToMiles(distance))
  return output;
}