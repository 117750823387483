export const VALIDATION_ERRORS = {
  EMAIL: "Please enter a valid email address",
  PASSWORD: "Please enter a valid password",
  PASSWORD_REPEAT: "Please ensure repeated password matches",
  FIRST_NAME: "Please enter a valid first name",
  LAST_NAME: "Please enter a valid last name",
  DATE_OF_BIRTH: "Please enter a valid date",
  GENDER: "Please select a gender",
  HOMETOWN: "Please enter a valid hometown",
  STATE: "Please enter a valid state/province",
  COUNTRY: "Please enter a valid country"
}

export const TOOLTIP_CONTENTS = {
  NAME: {
    title: "Why are you asking for my name?",
    body: "SpeedScore brings together folks who share a passion for speedgolf. " +
      "We want folks to get to know each other by name. " +
      "To make this possible, we encourage you to use your real name in the app. " +
      "If you are not comfortable with this, feel free tp use a pseudonym, but keep in mind that if you ever use SpeedSCore to enter competitions, you should use your real name."
  },
  DATE_OF_BIRTH: {
    title: "Why are you asking for my date of birth?",
    body: "In SpeedScore, you can register for speedgolf competitions that have age divisions. We need your age so" +
      "that we can present you with the competitions you are eligible for and place you in the correct age division."
  },
  NON_BINARY: {
    title: "What if I am non-binary?",
    body: "SpeedScore values users of all gender identities. " +
      "However, stroke and time pars are based on binary gender to facilitate fair handicap calculations and competitions. " +
      "Therefore, even if you do not identify as male or female, you need to pick one of these two designations " +
      "so that you can register for competitions, and so that the most appropriate stroke and time pars can be used " +
      "for your rounds."
  },
  HOMETOWN: {
    title: "Why are you asking for my hometown?",
    body: "In SpeedScore competitions, we list your hometown, state/province, and country in leaderboards and scorecards, so that others know where you're from. " +
      "These items will not be shared in your in-app interactions with others unless you choose to reveal them."
  },
  STATE: {
    title: "Why are you asking for my state/province?",
    body: "In SpeedScore competitions, we list your hometown, state/province, and country in leaderboards and scorecards, so that others know where you're from. " +
      "These items will not be shared in your in-app interactions with others unless you choose to reveal them."
  },
  COUNTRY: {
    title: "Why are you asking for my country?",
    body: "In SpeedScore competitions, we list your hometown, state/province, and country in leaderboards and scorecards, so that others know where you're from. " +
      "These items will not be shared in your in-app interactions with others unless you choose to reveal them."
  },
  PICTURE: {
    title: "Why are you asking me to upload a profile picture?",
    body: "Uploading a rectangular picture of your face is a great way to help other speedgolfers associate your name with your face. " +
      "In addition, in competitions, we display profile pictures in player scorecards."
  }
}