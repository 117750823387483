import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const Tabs = () => {
	// const { user } = useUserContext();
  const user = useSelector(state => state.user.user);
	const navigate = useNavigate();
  const location = useLocation();
	// let userAuthenticated = user.authenticated;
  // let userAuthenticated = false;
  // if (JSON.stringify(user) !== "{}") {
  //   userAuthenticated = user.accountInfo.authenticated;
  // }
  const userAuthenticated = useSelector(state => state.user.authenticated);

  const tabsOriginal = {
    feedActive: true,
    roundsActive: false,
    courseActive: false,
    buddiesActive: false,
    tournamentsActive: false,
  };

  const [tabsStatus, setTabStatus] = useState(tabsOriginal);

  const feedBtnClickHandler = () => {
    navigate("/feed");
  };

  const roundsBtnClickHandler = () => {
    navigate("/rounds");
  };

  const courseBtnClickHandler = () => {
    navigate("/courses");
  };

  const buddiesBtnClickHandler = () => {
    navigate("/buddies");
  };

  const tournamentsBtnClickHandler = () => {
    navigate('/competitions');
  }


  useEffect(() => {
    switch (location.pathname) {
      case "/feed":
        setTabStatus(() => {
          return cloneDeep({ ...tabsOriginal, ...{ feedActive: true } });
        });
        break;
      case "/rounds":
        setTabStatus(() => {
          return cloneDeep({ ...tabsOriginal, ...{ roundsActive: true, feedActive: false } });
        });
        break;
      case "/courses":
        setTabStatus(() => {
          return cloneDeep({ ...tabsOriginal, ...{ courseActive: true, feedActive: false } });
        });
        break;
      case "/buddies":
        setTabStatus(() => {
          return cloneDeep({ ...tabsOriginal, ...{ buddiesActive: true, feedActive: false } });
        });
        break;
      case "/competitions":
        setTabStatus(() => {
          return cloneDeep({ ...tabsOriginal, ...{ tournamentsActive: true, feedActive: false } });
        });
        break;
    }
  }, [location.pathname]);

  return (
    <div
      id='modeTabs'
      className={`modetab-container ${userAuthenticated ? "" : "hidden"}`}
      role='tablist'
      aria-label='App Modes'>
      <button
        id='feedMode'
        type='button'
        className={`modetab-btn ${tabsStatus.feedActive ? "modetab-selected" : ""}`}
        role='tab'
        tabIndex='0'
        aria-selected={`${tabsStatus.feedActive ? "true" : "false"}`}
        aria-controls='feedModeTab'
        onClick={feedBtnClickHandler}>
        Feed
      </button>
      <button
        id='roundsMode'
        type='button'
        className={`modetab-btn ${tabsStatus.roundsActive ? "modetab-selected" : ""}`}
        role='tab'
        tabIndex='-1'
        aria-selected={`${tabsStatus.roundsActive ? "true" : "false"}`}
        aria-controls='roundsModeTab'
        onClick={roundsBtnClickHandler}>
        Rounds
      </button>
      <button
        id='coursesMode'
        type='button'
        className={`modetab-btn ${tabsStatus.courseActive ? "modetab-selected" : ""}`}
        role='tab'
        tabIndex='-1'
        aria-selected={`${tabsStatus.courseActive ? "true" : "false"}`}
        aria-controls='coursesModeTab'
        onClick={courseBtnClickHandler}>
        Courses
      </button>
      <button
        id='buddiesMode'
        type='button'
        className={`modetab-btn ${tabsStatus.buddiesActive ? "modetab-selected" : ""}`}
        role='tab'
        tabIndex='-1'
        aria-selected={`${tabsStatus.buddiesActive ? "true" : "false"}`}
        aria-controls='buddiesModeTab'
        onClick={buddiesBtnClickHandler}>
        Buddies
      </button>
      <button
        id="tournamentsMode"
        type="button"
        className={`modetab-btn ${tabsStatus.tournamentsActive ? 'modetab-selected' : ''}`}
        role="tab"
        tabIndex="-1"
        aria-selected={`${tabsStatus.tournamentsActive ? 'true' : 'false'}`}
        aria-controls="tournamentsModeTab"
        onClick={tournamentsBtnClickHandler}
      >
        Competitions
      </button>
    </div>
  );
};

export default Tabs;
