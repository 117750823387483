import { lazy, Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./features/user/pages/LoginPage";
import RootLayout from "./RootLayout";
// import { useUserContext } from "./components/contexts/UserContext";
// import { validAccount } from "./services/userAuthenticationServices";
// import { useUserContext } from "./components/contexts/UserContext";

import Buddies from "./features/buddies/pages/BuddiesPage";
import Courses from "./features/course/pages/CoursePage";
import Error from "./features/error/pages/ErrorPage";
import Feed from "./features/feedItem/pages/FeedPage";
import LogRound from "./features/round/pages/LogRound";
import Rounds from "./features/round/pages/RoundsPage";
// need to remove this line.
// import Courses from "./components/CoursesComponents/CourseSection";
import CourseDetail from "./features/course/pages/CourseDetailPage";
import NewCourse from "./features/course/pages/NewCoursePage";
import Loader from "./features/shared/Loader";
import CreateAccountPage from "./features/user/pages/CreateAccountPage";
import SpeedScoreInfo from "./features/user/components/SpeedScoreInfo";
import ManageAccount from "./features/user/pages/ManageAccountPage";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { setRounds } from "./features/round/roundSlice";
import AutoLogin from "./features/user/components/autoLogin";
import EmailVerificationPage from "./features/user/pages/EmailVerificationPage";
import VerifyPasswordPage from "./features/user/pages/VerifyPasswordPage";
import EmailVerify from "./features/user/pages/EmailVerifyPage";
import ResetPassword from "./features/user/pages/ResetPassword";


const Tournaments = lazy(() => import("./features/competition/pages/TournamentPage"));
const AddTournament = lazy(() => import("./features/competition/pages/AddTournament"));

const App = () => {

  /**
   * All the contexts related use effects will be placed here
   */
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);
  const rounds = useSelector(state => state.rounds);
  AutoLogin(); // This will automatically attempt to log in the user if they have a valid cookie
  // useEffect(() => {
  //   const storedUser = localStorage.getItem(user.accountInfo.email);
  //   if (storedUser !== JSON.stringify(user)) {
  //     console.info("UserContextProvider: setting user from localStorage", user);
  //     localStorage.setItem(user.accountInfo.email, JSON.stringify(user));
  //   }
  // }, [user]);

  useEffect(() => {
    console.log('USER FROM USE EFFECT: ', user);
    if (!user || JSON.stringify(user) == '{}') return;
    const storedUser = localStorage.getItem(user.accountInfo.email);
    const newRounds = storedUser && JSON.parse(storedUser).rounds ? JSON.parse(storedUser).rounds : [];
    if (JSON.stringify(newRounds) !== JSON.stringify(rounds)) {
      // console.log('Updating global rounds state', newRounds);
      dispatch(setRounds(newRounds));
    }
  }, [user]);


  const router = createBrowserRouter([
    {
      path: "/",
      //element: <LandingPage />, // Set LandingPage as the root path
      element: <Login />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/info",
      element: <SpeedScoreInfo />,
    },
    {
      path: "/signup",
      element: <CreateAccountPage />,
    },
    { path: "/manageAccount", element: <ManageAccount /> },
    {
      path: 'account/verify-email',
      element: <EmailVerificationPage />
    }, 
    {
      path: 'auth/resend-email',
      element: <EmailVerify />
    },
    {
      path: 'account/forgot-password',
      element: <ResetPassword />
    },
    {
      path: 'account/reset-password',
      element: <VerifyPasswordPage />
    },
    {
      path: "/",
      element: (
        <Suspense fallback={<Loader />}>
          <RootLayout />
        </Suspense>
      ),
      children: [
        { path: "/feed", element: <Feed heading='Activity Feed' /> },
        { path: "/rounds", element: <Rounds /> },
        { path: "/courses", element: <Courses /> },
        { path: "/buddies", element: <Buddies heading='Buddies' /> },
        { path: "/competitions", element: <Tournaments /> },
      ],
    },
    {
      path: "/rounds/newRound",
      element: <LogRound />,
    },
    {
      path: "/rounds/editRound/:index",
      element: <LogRound />,
    },
    {
      path: "/courses/newCourse",
      element: <NewCourse />,
    },
    {
      path: "/courses/CourseDetail/:courseId",
      element: <CourseDetail />,
    },
    {
      path: "/competitions/newTournament",
      element: (
        <Suspense fallback={<Loader />}>
          <AddTournament />
        </Suspense>
      ),
      children: [{ index: true, path: ":id?/:tab", element: <AddTournament /> }],
    },
    {
      path: "*",
      element: <Error />,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default App;
