import Navbar from "../../shared/Navbar/Navbar";
import CreateAccountSection from "../components/CreateAccountSection/CreateAccountSection";

const CreateAccountPage = () => {
  return (
    <>
      <Navbar />
      <CreateAccountSection />
    </>
  );
};

export default CreateAccountPage;
