import { createSlice } from "@reduxjs/toolkit";
import { TOURNAMENT_STEPS, TOURNAMENT_STRUCTURE } from "./constants";
import { omit } from "lodash";

const initialState = {
  tournaments: [],
  activeTournament: null,
  availableSteps: [TOURNAMENT_STEPS.BASIC_INFO],
  allUsers: [],
}

export const competitionSlice = createSlice({
  name: 'tournaments',
  initialState,
  reducers: {
    // For the active tournament initialization
    setActiveTournamentAction: (state, action) => {
      state.activeTournament = action.payload;
      // console.log('setting active tournament');
      // console.log(action.payload);
      if (action.payload) {
        /*
        When we want to set an existing tournament as active:
        we take its data, remove the id, version number and published flag from it.
        Also check whether there's anything else present in the field, if it's empty then that is the current tab.
        */
        // Omit irrelevant fields
        const tournamentData = omit(action.payload, ['_id', '__v', 'published']);
        
        let completedSteps = [];
        let activeStep = '';

        // Iterate over TOURNAMENT_STEPS to determine the active step and completed steps
        for (const step of Object.values(TOURNAMENT_STEPS)) {
          const stepData = tournamentData[step];

          const isEmpty = stepData === null || stepData === undefined || stepData === '' || 
                          (Array.isArray(stepData) && stepData.length === 0) || 
                          (typeof stepData === 'object' && Object.keys(stepData).length === 0);

          if (isEmpty && !activeStep) {
            activeStep = step;
            break;
          }

          if (!isEmpty) {
            completedSteps.push(step);
          }
        }

        // console.log(activeStep);
        // Set the active step to the first incomplete one, or the last completed if all are filled
        if (!activeStep) {
          activeStep = completedSteps[completedSteps.length - 1] || TOURNAMENT_STEPS.BASIC_INFO;
        }

        // Add the next step to the completed steps if it exists
        const nextStep = TOURNAMENT_STRUCTURE[completedSteps[completedSteps.length - 1]]?.next;
        if (nextStep) {
          completedSteps.push(nextStep);
        }

        // const completedSteps = Object.keys(omit(action.payload, ['_id', '__v', 'published']));
        // // Next, we need to add the next step, as it should be open.
        // console.log(TOURNAMENT_STRUCTURE[completedSteps[completedSteps.length - 1]]);
        // const nextStep = TOURNAMENT_STRUCTURE[completedSteps[completedSteps.length - 1]].next;

        // But if the last tab has already been filled in, its next will be null, so it doesn't need to be added.
        // if (nextStep) {
        //   completedSteps.push(nextStep);
        // }
        state.availableSteps = completedSteps;
      }
    },
    // To update the active tournament, for example when saving tabs.
    updateActiveTournamentAction: (state, action) => {
      // console.log(state, action);
      console.log(action.payload);
      state.activeTournament = {
        _id: action.payload.id,
        ...state.activeTournament,
        ...action.payload.schema
      }
    },
    // To update the available tabs.
    updateAvailableSteps: (state, action) => {
      const nextStep = TOURNAMENT_STRUCTURE[action.payload].next;
      if (!state.availableSteps.includes(nextStep)) {
        state.availableSteps.push(nextStep);
      }
    },
    // To reset the available tabs.
    resetAvailableSteps: (state) => {
      state.availableSteps = [TOURNAMENT_STEPS.BASIC_INFO]
    },
    // To initialize all tournaments on the tournaments page.
    setTournamentsAction: (state, action) => {
      state.tournaments = action.payload
    },
    setAllUsers: (state, action) => {
      state.allUsers = action.payload;
    }
  }
})

export const {
  setTournamentsAction,
  setActiveTournamentAction,
  updateActiveTournamentAction,
  resetAvailableSteps,
  updateAvailableSteps,
  setAllUsers,
} = competitionSlice.actions;

export default competitionSlice.reducer;
