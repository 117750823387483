import PageUnderConstruction from "../../shared/PageUnderConstruction/PageUnderConstruction";

const Buddies = props => {
  return (
    <>
      <div id='buddiesModeTab' className='mode-page' role='tabpanel' aria-label='Buddies Tab' tabIndex='0'>
        <PageUnderConstruction heading={props.heading} />
        <button id='buddiesModeActionBtn' type='button' className='float-btn'>
          <span className='fas fa-search fa-fw' aria-hidden='true'></span>
          Find Buddies
        </button>
      </div>
    </>
  );
};

export default Buddies;
