const NoRoundsMessage = () => {
  return (
    <tr>
      <td colSpan='7' scope='rowgroup'>
        <i>No rounds added yet</i>
      </td>
    </tr>
  );
};

export default NoRoundsMessage;
