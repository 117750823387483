import { MILE_IN_FEET, MILE_IN_KM } from "./constants";

export const convertMilesToFeet = (miles) => miles * MILE_IN_FEET;
export const convertFeetToMiles = (feet) => {
    if (!feet) {
        return;
    }
    return feet / MILE_IN_FEET;
};

export const convertKmToMiles = (km) => km / MILE_IN_KM;

export const convertMilesToKm = (miles) => miles * MILE_IN_KM;

export const convertFeetToKilometers = (feet) => {
    const kilometers = feet * 0.0003048;
    return kilometers.toFixed(2).toString()
  }
  