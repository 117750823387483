import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  convertFeetToMiles,
  convertKmToMiles,
  convertMilesToFeet,
  convertMilesToKm,
  convertFeetToKilometers,
} from "../utils/utils";
import { format } from "date-fns/format";
import { notifyMessage } from "../../../services/toasterServices";
import { useSelector, useDispatch } from "react-redux";
import { setRounds } from "../roundSlice";
import { fetchRoundByIdAction, logRoundAction, updateRoundAction } from "../roundActions";
import { fetchCourseById } from "../../course/courseActions";

const useLogRound = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  // const { user } = useUserContext();
  const user = useSelector((state) => state.user.user);
  // const { setRounds } = useRoundsContext();
  // const { authenticated, email } = user.accountInfo;
  const { authenticated } = useSelector((state) => state.user);
  const { email } = user?.accountInfo?.email || "";
  // const { rounds, roundCount } = JSON.parse(localStorage.getItem(email));
  const rounds = useSelector((state) => state.rounds);
  const roundCount = rounds.length;

  useEffect(() => {
    // console.log('Inside useLogRound useEffect', authenticated);
    if (!authenticated) navigate("/login");
  }, [authenticated]);

  const { index } = useParams();
  const isNew = index == null;
  // const round = rounds.find(({ _id: roundId }) => roundId == index);
  const [round, setRound] = useState(rounds.find(({ _id: roundId }) => roundId == index));
  // const [loadedRounds, setLoadedRounds] = useState(isNew ? true : false);

  async function fetchCourseDetailsCallBack() {
    if (!round || !round.courseId) return;
    if (!isNew) {
      await dispatch(fetchCourseById(round.courseId.id), navigate);
    }
  }

  /**
   * The following use effect is exported to a call back function above, so that there is could be synchoronizaition between
   * child component useEffect and parent component useEffect
   * We need to execute this function once all the courses are fetched by child courses component
   * else this tees data will be over written by that function
   */
  // useEffect(() => {
  //   if (!isNew) {
  //     /**
  //      * No point in fetching the round again,
  //      * we can fetch the course linked with this round to populate the scorecard?
  //      */
  //     // dispatch(fetchRoundByIdAction(index, navigate));
  //     dispatch(fetchCourseById(round.courseId.id), navigate)
  //     // setLoadedRounds(true);
  //   }
  // },[])

  /**
   * We need to update the rounds data when ever the rounds is fetched from the server,
   * Hence declaring that as a state variable and updating it when ever the rounds is fetched from the server
   */
  // useEffect(() => {
  //   setRound(rounds.find(({ _id: roundId }) => roundId == index));
  // }, [rounds])

  // * Defaulting these values, need to add more input fields in future
  const [holeByHole, setHoleByHole] = useState(
    round?.holeData?.length > 0
      ? round?.holeData.map((hole) => {
          return {
            ...hole,
            minutes: hole.elapsedTime ? Math.floor(hole.elapsedTime / 60) : null,
            seconds: hole.elapsedTime ? (hole.elapsedTime % 60).toString().padStart(2, "0") : null,
          };
        })
      : Array(18).fill({
          strokes: null,
          strokesToPar: 0,
          timeToPar: 0, // why is this a number? it is number of milliseconds?
          startTime: null,
          finishTime: null,
          elapsedTime: null, // by default elapsed time is 0
          minutes: null,
          seconds: null,
          putts: null,
          shotInfo: [], //Optional -- use only if user tracks shot info
        })
  );
  const preferredUnits = user?.preferences?.preferredUnits;
  const [distanceUnit, setDistanceUnit] = useState(preferredUnits == "imperial" ? "miles" : "km");

  // console.log('Rounds Data: ', round.strokes)

  const { setValue, getValues, watch, ...methods } = useForm({
    defaultValues: round
      ? {
          ...round,
          strokes: round.strokes,
          distance:
            distanceUnit === "miles"
              ? convertFeetToMiles(round.distance)?.toFixed(2)
              : convertFeetToKilometers(round.distance),
          minutes: Math.round(round.time / 60),
          seconds: (round.time % 60).toString().padStart(2, "0"),
          date: format(new Date(round.date), "yyyy-MM-dd"),
          course: round.courseId.shortName,
          SGS: (() => {
            const strokes = round.strokes;
            const minutes = Math.floor(round.time / 60);
            const seconds = (round.time % 60).toString().padStart(2, "0");
            return strokes + minutes + ":" + seconds;
          })(),
        }
      : {
          course: "",
          date: format(new Date(), "yyyy-MM-dd"),
          type: "Practice",
          holes: 18,
          strokes: 80,
          minutes: 60,
          seconds: "00",
          time: 3600,
          SGS: "140:00",
          holeByHole: [],
          tee: "",
          keepPrivate: false,
          scorecardButtonEnable: false,
        },
    reValidateMode: "onSubmit",
  });

  // const formData = watch();
  // useEffect(() => {
  //   console.log('Changed Form Data', formData);
  // }, [formData])

  const cleanUpScoreCard = () => {
    setHoleByHole(
      Array(18).fill({
        strokes: null,
        strokesToPar: 0,
        timeToPar: 0, // why is this a number? it is number of milliseconds?
        startTime: null,
        finishTime: null,
        putts: null,
        shotInfo: [], //Optional -- use only if user tracks shot info,
        minutes: null,
        seconds: null,
        elapsedTime: null,
      })
    );
  };

  const sgsHandler = () => {
    const [strokes, minutes, seconds] = getValues(["strokes", "minutes", "seconds"]);
    let formattedSeconds = seconds.toString().padStart(2, "0");
    if (formattedSeconds.length > 2) {
      formattedSeconds = formattedSeconds.slice(-2);
    }
    // console.log('FORM DATA STROKES', strokes);
    setValue("SGS", strokes + minutes + ":" + formattedSeconds);
    setValue("seconds", formattedSeconds);

    // clean up score card of sgsHandler is executed.
    // TODO: This is not working. Need to think about in future
    // cleanUpScoreCard();
  };

  const validateSeconds = () => {
    const seconds = watch("seconds");
    if (seconds > 59) {
      setValue("seconds", 59);
    }
    else if (seconds.toString().length > 2) {
      alert("Invalid Seconds Detected");
      setValue("seconds", '00');
    }
  }

  const distanceUnitKeyHandler = (e) => {
    if (e.key === "ArrowRight" && distanceUnit === "miles") {
      distanceUnitHandler();
    }

    if (e.key === "ArrowLeft" && distanceUnit === "km") {
      distanceUnitHandler();
    }
  };

  const distanceUnitHandler = () => {
    setDistanceUnit((prev) => (prev === "miles" ? "km" : "miles"));
    const [currentDistance] = getValues(["distance"]);
    const convertFunction = distanceUnit === "miles" ? convertMilesToKm : convertKmToMiles;

    setValue("distance", convertFunction(currentDistance).toFixed(2));
  };

  const formatDistance = () => {
    const currentDistance = watch("distance");

    if (currentDistance) {
      setValue("distance", Number(currentDistance).toFixed(2));
    }
  };

  const closeLogPage = () => navigate("/rounds");

  const onSubmit = (data) => {
    const distance = convertMilesToFeet(distanceUnit === "miles" ? +data.distance : convertKmToMiles(+data.distance));

    const updatedRounds = isNew
      ? [...rounds, { ...data, roundNum: roundCount + 1, distance, holeByHole }]
      : rounds.map((round) => {
          if (round.roundNum == index) {
            return {
              ...round,
              ...data,
              distance,
              holeByHole,
            };
          }
          return round;
        });

    // localStorage.setItem(
    //   user.accountInfo.email,
    //   JSON.stringify({ ...user, rounds: updatedRounds, roundCount: roundCount + 1 })
    // );
    // setRounds(updatedRounds);
    // dispatch(setRounds(updatedRounds));
    // * Not needed
    // const elapsedTimeComputedHoleByHole = holeByHole.map((hole) => {
    //   const minutes = hole.minutes ? hole.minutes * 60 : 0;
    //   const seconds = hole.seconds ? +hole.seconds : 0;
    //   const elapsedTime = (!minutes || !seconds) ? null : minutes + seconds;
    //   // TODO: Time field is being changed in the dispatcher async thunk. Need to shift that logic here!
    //   return {
    //     ...hole,
    //     elapsedTime: elapsedTime,
    //   };
    // })
    if (isNew) {
      dispatch(logRoundAction({ ...data, distance, holeData: holeByHole }, navigate));
    } else if (!isNew) {
      dispatch(updateRoundAction(index, { ...data, distance, holeData: holeByHole }, navigate));
    }

    // const notifyMessageTitle = isNew ? "New round created!" : "Round Updated!";
    // notifyMessage("success", notifyMessageTitle, 3000, "colored", "top-center");

    // navigating to rounds page here itself is causing asynchronous issues
    // The dispatch handlers will take care of the navigation
    // closeLogPage();
  };

  const clearScorecardTime = () => {
    setHoleByHole(
      holeByHole.map((hole) => {
        return {
          ...hole,
          minutes: null,
          seconds: null,
          elapsedTime: null,
          timeToPar: 0
        };
      })
    );
  }

  return {
    isNew,
    onSubmit,
    sgsHandler,
    distanceUnit,
    formatDistance,
    distanceUnitHandler,
    distanceUnitKeyHandler,
    closeLogPage,
    methods,
    holeByHole,
    setHoleByHole,
    user,
    round,
    setValue,
    getValues,
    watch,
    fetchCourseDetailsCallBack,
    cleanUpScoreCard,
    validateSeconds,
    clearScorecardTime,
    navigate,
    index
  };
};

export default useLogRound;
