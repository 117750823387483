import { createSlice } from "@reduxjs/toolkit";
import { initial, reduce } from "lodash";
import { logoutUser } from "../user/userSlice";


const roundsSlice = createSlice({
  name: 'rounds',
  initialState: [],
  reducers: {
    setRounds(state, action) {
      console.log('Setting rounds in state', action.payload);
      return action.payload
    },
    setRound(state, action) {
      const updatedRounds = state.map(round => {
        if (round._id === action.payload._id) {
          console.log('Round Id matched')
          return action.payload;
        }
        return round;
      })
      return updatedRounds;
    },
    addRound(state, action){
      state.push(action.payload);
    },
    removeRound(state, action){
      const updatedRounds = state.filter(round => round._id !== action.payload);
      localStorage.setItem('rounds', JSON.stringify(updatedRounds));
      return updatedRounds
    },
    updateRound(state, action){
      const {id, ...newRound} = action.payload;
      const roundIndex = state.findIndex(round => round.id === id);
      state[roundIndex] = newRound;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser, (state, action) => {
      return []
    })
  },
})

export const { setRounds, addRound, removeRound, updateRound, setRound } = roundsSlice.actions;
export default roundsSlice.reducer;