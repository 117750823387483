/********************************************************************
 * @file userServices.js
 * @descr
 * Defines functions to update the user data in the database
 * via API calls.
 ********************************************************************/
import axios from "axios";

// import { env } from 'dotenv';
const apiURL = process.env.REACT_APP_API_BASE_ENDPOINT;

export const loginUser = async (email, password) => {
  try {
    const response = await axios.post(apiURL + "auth/login", {
      email,
      password
    });
    return {
      status: response.status,
      data: response.data
    };
  } catch (error) {
    return {
      status: error.response.status,
      data: error.response.data
    };
  }
};

export const verifyEmailService = async (userId, token) => {
  try {
    const EMAIL_VERIFICATION_ENDPOINT = process.env.REACT_APP_EMAIL_VERIFICATION_ENDPOINT;
    const response = await axios.post(apiURL + `${EMAIL_VERIFICATION_ENDPOINT}`, {
      userId,
      token
    });
    return {
      status: response.status,
      data: response.data
    };
  } catch (error) {
    return {
      status: error.response.status,
      data: error.response.data
    };
  }
};

export const verifyPasswordService = async (token, password, confirmPassword) => {
  try {
    const PASSWORD_VERIFICATION_ENDPOINT = process.env.REACT_APP_PASSWORD_VERIFICATION_ENDPOINT;
    const response = await axios.post(apiURL + `${PASSWORD_VERIFICATION_ENDPOINT}`, {
      token,
      password,
      confirmPassword,
    });
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    return {
      status: error.response?.status || 500,
      data: error.response?.data || { message: "Server error" },
    };
  }
};

export const resendVerificationEmailService = async email => {
  try {
    const RESEND_EMAIL_ENDPOINT = process.env.REACT_APP_RESEND_EMAIL_ENDPOINT;
    const response = await axios.post(apiURL + `${RESEND_EMAIL_ENDPOINT}`, {
      email
    });
    return {
      status: response.status,
      data: response.data
    };
  } catch (error) {
    return {
      status: error.response.status,
      data: error.response.data
    };
  }
};

export const logoutUser = async refreshToken => {
  try {
    const response = await axios.post(apiURL + "auth/logout", {
      refreshToken
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const refreshAuthToken = async refreshToken => {
  try {
    const response = await axios.post(apiURL + "auth/refresh-token", {
      refreshToken
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateUser = async (updatedUser, jwt, userId) => {
  try {
    const response = await axios.put(apiURL + "users/update-user/" + userId, updatedUser, {
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "multipart/form-data"
      }
    });
    return {
      status: response.status,
      data: response.data
    };
  } catch (error) {
    return {
      status: error.response.status,
      data: error.response.data
    };
  }
};

export const createUser = async newUserData => {
  try {
    const response = await axios.post(apiURL + "users/register", newUserData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return {
      status: response.status,
      data: response.data
    };
  } catch (error) {
    return {
      status: error.response.status,
      data: error.response.data
    };
  }
};

export const getUser = async jwtToken => {
  try {
    const response = await axios.get(apiURL + "users/get-user", {
      headers: {
        Authorization: `Bearer ${jwtToken}`
      }
    });
    return {
      status: response.status,
      data: response.data
    };
  } catch (error) {
    return {
      status: error.response.status,
      data: error.response.data
    };
  }
};

export const sendPasswordResetLink = async email => {
  try {
    const response = await axios.post(apiURL + "auth/send-password-reset-link", {
      email
    });
    return {
      status: response.status,
      data: response.data
    };
  } catch (error) {
    return {
      status: error.response.status,
      data: error.response.data
    };
  }

}
/* Service functions that make API calls involving the user object go in this file */
