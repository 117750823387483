/***********************************************************************
 * @file: userSlice.js
 * @descr:
 * The userSlice.js file defines the user slice of the Redux store.
 * The user slice maintains the state of the user object in the Redux store.
 ***********************************************************************/
import { createSlice } from "@reduxjs/toolkit";
import User from "../../models/user.model";
import Cookies from "js-cookie";
import _ from "lodash";

const initialState = {
  user: {},
  isLoading: false,
  error: null,
  tokens: {
    jwtToken: null,
    jwtTokenExpiry: null,
    refreshToken: null,
    refreshTokenExpiry: null
  },
  authenticated: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      // state.user = action.payload;
      state.user = {
        accountInfo: { ...action.payload.accountInfo },
        personalInfo: action.payload.personalInfo,
        speedgolfInfo: action.payload.speedgolfInfo,
        preferences: action.payload.preferences,
        _id: action.payload._id
      };
      state.authenticated = true;
      //TODO get item user object from local storage
      // let tempObj = localStorage.getItem(state.user.accountInfo.email);
      // if (tempObj) {
      //   tempObj = JSON.parse(tempObj);
      // }
      // tempObj.accountInfo= action.payload.accountInfo;
      // tempObj.personalInfo = action.payload.personalInfo;
      // tempObj.speedgolfInfo = action.payload.speedgolfInfo;
      // tempObj.preferences = action.payload.preferences;
      // tempObj._id = action.payload._id;
      // localStorage.setItem(state.user.accountInfo.email, JSON.stringify(tempObj));
      const stateClone = _.cloneDeep(state);
      localStorage.setItem(state.user.accountInfo.email, JSON.stringify(stateClone))
      //localStorage.setItem(state.user.accountInfo.email, JSON.stringify(state));
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    // updateUser: (state, action) => {

    //   state.user = {
    //     accountInfo: { ...state.user.accountInfo},
    //     personalInfo: action.payload.personalInfo,
    //     speedgolfInfo: action.payload.speedgolfInfo,
    //     preferences: action.payload.preferences,
    //     _id: action.payload._id
    //   };
    //   state.authenticated = true;
    //   //update state.user
    // },
    loginUser: (state, action) => {

      state.user = {
        accountInfo: { ...action.payload.accountInfo },
        personalInfo: {
          ...action.payload.personalInfo,
          //authenticated: true,
          profilePic: action.payload.personalInfo.profilePic || "https://avatars.dicebear.com/api/human/hello1.svg"
        },
        speedgolfInfo: action.payload.speedgolfInfo,
        preferences: action.payload.preferences,
        _id: action.payload._id
      };
      state.tokens = {
        jwtToken: action.payload.jwtToken,
        jwtTokenExpiry: action.payload.jwtTokenExpiry,
        refreshToken: action.payload.refreshToken.token,
        refreshTokenExpiry: action.payload.refreshToken.expiresAt
      };
      state.authenticated = true;
      //localStorage.setItem(state.user.accountInfo.email, JSON.stringify(state));
      const stateClone = _.cloneDeep(state);
      //saving the cloned state to local storage
      localStorage.setItem(state.user.accountInfo.email, JSON.stringify(stateClone));
    },

    logoutUser: state => {
      state.user = {};
      state.tokens = {
        jwtToken: null,
        jwtTokenExpiry: null,
        refreshToken: null,
        refreshTokenExpiry: null
      };
      state.authenticated = false;

      Cookies.remove("user-cookie");
      Cookies.remove("user-info");
      Cookies.remove("user-refresh-token");
       // Clear the user-related data from localStorage
       const allKeys = Object.keys(localStorage);
       const emailKeys = allKeys.filter(key => key.includes('@'));
       emailKeys.forEach(key => localStorage.removeItem(key));
       state.isLoading = false;
      state.error = null;
      // state.user = {};
      // state.authToken = null;
      console.log("LOGOUT USER REDUCER");
      //TODO Clear local storage here
      localStorage.removeItem('courses')
      localStorage.removeItem('rounds')
      console.log(initialState);
      //state = initialState;
      //return state;
    },

    refreshAuthToken: (state, action) => {
      state.authToken = action.payload;
    }
  }
});

export const {
  setUser,
  setLoading,
  setError,
  loginUser,
  logoutUser,
  refreshAuthToken,
} = userSlice.actions;

export default userSlice.reducer;
