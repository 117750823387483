import React, { useEffect, useState } from "react";
import moment from "moment";

function convertFeetToYards(feet) {
  const yards = feet / 3;
  return Math.floor(yards);
}

function convertFeetToMeters(feet) {
  const meters = feet * 0.3048;
  return Math.floor(meters);
}

function convertFeetToMiles(feet) {
  const miles = feet / 5280;
  return miles.toFixed(2).toString() + " mi";
}

function convertFeetToKilometers(feet) {
  const kilometers = feet * 0.0003048;
  return kilometers.toFixed(2).toString() + " km";
}

function translateDistanceByType(feet, type, isMetric) {
  switch (type) {
    case "golfRunDistance":
      return isMetric ? convertFeetToMeters(feet) : convertFeetToYards(feet);

    case "transRunDistance":
      return isMetric ? convertFeetToMeters(feet) : convertFeetToYards(feet);

    case "runDistance":
      return isMetric ? convertFeetToMeters(feet) : convertFeetToYards(feet);

    case "outRunDistance":
      return isMetric ? convertFeetToKilometers(feet) : convertFeetToMiles(feet);

    case "outTransRunDistance":
      return isMetric ? convertFeetToKilometers(feet) : convertFeetToMiles(feet);

    case "outGolfRunDistance":
      return isMetric ? convertFeetToKilometers(feet) : convertFeetToMiles(feet);

    case "inRunDistance":
      return isMetric ? convertFeetToKilometers(feet) : convertFeetToMiles(feet);

    case "inTransRunDistance":
      return isMetric ? convertFeetToKilometers(feet) : convertFeetToMiles(feet);

    case "inGolfRunDistance":
      return isMetric ? convertFeetToKilometers(feet) : convertFeetToMiles(feet);

    case "totalRunDistance":
      return isMetric ? convertFeetToKilometers(feet) : convertFeetToMiles(feet);

    case "totalTransRunDistance":
      return isMetric ? convertFeetToKilometers(feet) : convertFeetToMiles(feet);

    case "totalGolfRunDistance":
      return isMetric ? convertFeetToKilometers(feet) : convertFeetToMiles(feet);

    default:
      return feet;
  }
}

function getUnitForDistanceType(type, isMetric) {
  switch (type) {
    case "RUN_DISTANCE":
      return isMetric ? " (Meters)" : " (Yards)";

    case "TRANS_DISTANCE":
      return isMetric ? " (Meters)" : " (Yards)";

    case "GOLF_DISTANCE":
      return isMetric ? " (Meters)" : " (Yards)";

    default:
      return "Feet";
  }
}
const handleTabPress = (event) => {
  if (event.key === "Tab") {
    event.preventDefault(); // Prevent the default tab behavior

    const input = event.target;

    // Get the current row and column indices
    const currentRow = input.closest("tr").rowIndex;
    const currentCol = input.closest("td").cellIndex;

    // Find the table element
    const table = input.closest("table");

    // Get the next row's input in the same column
    const nextRow = table.rows[currentRow + 1];

    if (nextRow) {
      const nextInput = nextRow.cells[currentCol].querySelector("input");
      if (nextInput) {
        nextInput.focus();
      }
    }
  }
};

const ScoreCardModal = ({
  setHoleByHole,
  beforeSaveRoundData,
  selectedCourse,
  showHoleTimes,
  setShowHoleTimes,
  setRoundStartTime,
  roundStartTime,
  holeByHole,
  selectedTee,
  user,
  outData,
  inData,
  totalData,
  totalStrokesData,
  updateGolfRoundMetrics,
  cleanUpScoreCard,
  scorecardModalRef,
  hideScorecard,
}) => {
  // return(
  //   <h1>welcome</h1>
  // )

  const [tempTime, setTempTime] = useState(null);
  const preferredUnits = user.preferences?.preferredUnits || "imperial";
  const isMetric = preferredUnits === "metric";

  // console.log("SELECTED TEE FROM SCORECARD : ", selectedTee);
  const [holeMinutes, setHoleMinutes] = useState(null);
  const [holeSeconds, setHoleSeconds] = useState(null);

  const holeByHoleTimeControl = (item, value, type) => {
    const idealTime = item[user?.personalInfo?.parGender + "TimePar"];
    const newHoleByHoleData = holeByHole.map((hole, index) => {
      if (index === item?.number - 1) {
        const currentHoleSeconds = hole.seconds ?? 0;
        const currentHoleMinutes = hole.minutes ?? 0;
        let timeToPar = 0
        if (type == "minutes") {
          timeToPar = (parseInt(value) * 60) + parseInt(currentHoleSeconds) - idealTime;
        } else if (type == "seconds") { 
          timeToPar = (parseInt(currentHoleMinutes) * 60) + parseInt(value) - idealTime;
        }
        return {
          ...hole,
          [type]: value,
          timeToPar: Math.round(timeToPar),
        };
      }
      return hole;
    });
    return newHoleByHoleData;
  };

  const updateHoleTime = (item, value, type) => {
    const newHoleByHoleData = holeByHoleTimeControl(item, value, type);
    setHoleByHole(newHoleByHoleData);

    if (type === "minutes") {
      setHoleMinutes(value);
    } else if (type === "seconds") {
      setHoleSeconds(value);
    }
  };

  const validateAndSetElapsedTime = (item, TYPE) => {
    if (TYPE === "minutes" && holeMinutes) {
      // TODO: Validate minutes rule
      if (holeMinutes > 400 || holeMinutes < 1) {
        alert("Minutes should be between 10 and 400");
        setHoleMinutes(null);
        const newHoleByHoleData = holeByHoleTimeControl(item, "", "minutes");
        setHoleByHole(newHoleByHoleData);
        return;
      }
      const newHoleByHoleData = holeByHoleTimeControl(item, holeMinutes, "minutes");
      setHoleByHole(newHoleByHoleData);
    } else if (TYPE === "seconds" && holeSeconds) {
      if (holeSeconds > 59 || holeSeconds < 0) {
        alert("Seconds should be between 0 and 59");
        setHoleSeconds(null);
        const newHoleByHoleData = holeByHoleTimeControl(item, "", "seconds");
        setHoleByHole(newHoleByHoleData);
        return;
      }
      const newHoleByHoleData = holeByHoleTimeControl(item, holeSeconds, "seconds");
      setHoleByHole(newHoleByHoleData);
    }
    setHoleMinutes(null);
    setHoleSeconds(null);
  };

  const validTimeCheckAndUpdate = (item) => {
    const selectedTime = tempTime;
    const minTime = holeByHole[item?.number - 1]["startTime"]
      ? moment(holeByHole[item?.number - 1]["startTime"], "HH:mm:ss")
      : moment(roundStartTime, "HH:mm:ss");
    console.log(minTime);
    const selectedMoment = moment(selectedTime, "HH:mm:ss");
    if (selectedMoment.isBefore(minTime)) {
      alert(`Please select a time after  ${minTime.format("HH:mm:ss")} `);
      return;
    }

    const oneHourCheck = minTime.clone().add(1, "hours");
    if (
      (selectedMoment.isAfter(oneHourCheck) || selectedMoment.isSame(oneHourCheck)) &&
      holeByHole[item?.number - 1]["startTime"]
    ) {
      alert(`Please select a time within 1 hour after ${minTime.format("HH:mm:ss")}`);
      return;
    }
    if (holeByHole?.length == item?.number) {
      let timeValue = moment.duration(selectedMoment.diff(moment(roundStartTime, "HH:mm:ss"))).asSeconds();
      if (timeValue > 14400) {
        alert("Finish time cannot be more than 4 hours after start time");
        return;
      }
    } else if (holeByHole[holeByHole?.length - 1]["finishTime"]) {
      if (selectedMoment.isAfter(moment(holeByHole[holeByHole?.length - 1]["finishTime"], "HH:mm:ss"))) {
        alert("Round time cannot be after final finish time");
        return;
      }
    }

    const updatedTempHoleData = holeByHole.map((hole, index) => {
      if (index === item?.number - 1 && tempTime) {
        const finishTimeInMilliseconds = moment.duration(tempTime, "HH:mm:ss").asSeconds();
        const startTimeInMilliseconds = moment
          .duration(holeByHole[item?.number - 1]["startTime"], "HH:mm:ss")
          .asSeconds();
        const duration = finishTimeInMilliseconds - startTimeInMilliseconds;
        const timePar = item[user?.personalInfo?.parGender + "TimePar"];
        return { ...hole, finishTime: tempTime, timeToPar: timePar - duration };
      }
      // This is the start time for the next round
      // This says that, the current hole finish time is the start time for the next hole
      if (index === item?.number && tempTime) {
        return { ...hole, startTime: tempTime };
      }
      return hole;
    });
    setHoleByHole(updatedTempHoleData);
  };

  const isDecimal = (value) => {
    if (typeof value === "number" || typeof value === "string") {
      return /^-?\d*\.\d+$/.test(value.toString());
    }
    return false;
  };

  const scoreCardValidator = () => {
    let valid = true;
    let error = "";
    for (let i = 0; i < holeByHole.length; i++) {
      if (holeByHole[i].strokes === null || holeByHole[i].strokes < 0) {
        error = "Strokes cannot be empty or negative";
        valid = false;
        break;
      } else if (isDecimal(holeByHole[i].strokes)) {
        error = "Strokes cannot be a decimal value";
        valid = false;
        break;
      }
      if (holeByHole[i].minutes !== null && holeByHole[i].minutes !== '' && holeByHole[i].minutes < 0) {
        error = "Minutes cannot be negative";
        valid = false;
        break;
      } else if (isDecimal(holeByHole[i].minutes)) {
        error = "Minutes cannot be a decimal value";
        valid = false;
        break;
      } else if (holeByHole[i].minutes !== null && holeByHole[i].minutes.toString().length > 2) {
        error =  "Minutes cannot be 3 digits";
        valid = false;
        break;
      }
      if (holeByHole[i].seconds !== null && holeByHole[i].seconds !== '' && holeByHole[i].seconds < 0) {
        error = "Seconds cannot be negative";
        valid = false;
        break;
      } else if (holeByHole[i].seconds !== null && holeByHole[i].seconds > 59) {
        error = "One or More Seconds value is greater than 59";
        valid = false;
        break;
      } else if (isDecimal(holeByHole[i].seconds)) {
        error = "Seconds cannot be a decimal value";
        valid = false;
        break;
      } else if (holeByHole[i].seconds !== null && holeByHole[i].seconds.toString().length > 2) {
        error = "Seconds cannot be 3 digits";
        valid = false;
        break;
      }
    }
    return { valid, error };
  };

  const scoreCardHandler = () => {
    const { valid, error } = scoreCardValidator();
    if (!valid) {
      alert(error);
      return;
    }
    hideScorecard();
    updateGolfRoundMetrics();
  };


  return (
    <>
      <div
        className='modal fade'
        id='scoreCardModal'
        aria-hidden='true'
        aria-labelledby='exampleModalToggleLabel'
        ref={scorecardModalRef}
        tabindex='-1'>
        <div className='modal-dialog modal-lg modal-dialog-scrollable'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h1 className='modal-title fs-5' id='exampleModalToggleLabel'>
                Round Scorecard
              </h1>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={() => {
                  setHoleByHole([...beforeSaveRoundData]);
                  hideScorecard();
                }}></button>
            </div>
            <div className='modal-body'>
              <div className='header-scorecard-title'>
                <h5 className='centered'>{selectedCourse?.name.split(',')[0]}</h5>
              </div>
              {/* <h5 className="centered">{selectedCourse?.name}</h5> */}
              {showHoleTimes ? (
                <>
                  {/* <div className='d-flex flex-row justify-content-end align-items-center'>
                    <div className='d-flex flex-row justify-content-center align-items-center'>
                      <label className='text-center '>Round Start Time: </label>
                      <input
                        id='teeStartDate'
                        className='teeStartDate'
                        type='time'
                        step={2}
                        aria-describedby='teeStartDateDescr'
                        value={roundStartTime}
                        onChange={(e) => {
                          setRoundStartTime(e.target.value);
                          const updatedTempHoleData = holeByHole.map((hole, index) => {
                            if (index === 0 && e.target.value) {
                              return { ...hole, startTime: e.target.value };
                            }
                            return hole;
                          });
                          setHoleByHole(updatedTempHoleData);
                        }}
                        //onChange={onChange}
                      />
                    </div>
                    <button
                      className='btn btn-sm btn-outline-dark hideHole '
                      onClick={() => setShowHoleTimes((prev) => !prev)}>{`<< Hide Hole Times`}
                    </button>
                  </div>
                  <div className='d-flex flex-row justify-content-center align-items-center mt-2 finish-time-container'>
                    <label className='text-center '>Round Finish Time: </label>
                    <input
                      id='teeStartDate'
                      className='teeStartDate'
                      type='time'
                      step={2}
                      aria-describedby='teeStartDateDescr'
                      value={holeByHole[holeByHole?.length - 1]["finishTime"]}
                      onChange={(e) => {
                        const updatedTempHoleData = holeByHole.map((hole, index) => {
                          if (index == holeByHole?.length - 1 && e.target.value) {
                            return { ...hole, finishTime: e.target.value };
                          }
                          return hole;
                        });
                        setHoleByHole(updatedTempHoleData);
                      }}
                      //onChange={onChange}
                    />
                  </div> */}
                  <div className='centered'>
                    <button className='btn btn-sm btn-outline-dark' onClick={() => setShowHoleTimes((prev) => !prev)}>
                      {`<< Hide Hole Times`}
                    </button>
                  </div>
                </>
              ) : (
                <div className='centered'>
                  <button className='btn btn-sm btn-outline-dark' onClick={() => setShowHoleTimes((prev) => !prev)}>
                    Show Hole Times {">>"}
                  </button>
                </div>
              )}
              {/* <AlertBox isMetric={isMetric} /> */}
              <div className='tableContainer'>
                <table className='table table-responsive table-striped table-sm table-bordered' id='scorecard'>
                  <thead className='sticky-top'>
                    <tr>
                      <th className='centered' scope='col'>
                        Hole
                      </th>
                      <th className='centered' scope='col'>
                        RUN DIST {getUnitForDistanceType("RUN_DISTANCE", isMetric)}
                      </th>
                      <th className='centered' scope='col'>
                        TRANS DIST {getUnitForDistanceType("TRANS_DISTANCE", isMetric)}
                      </th>
                      <th className='centered' scope='col'>
                        GOLF DIST {getUnitForDistanceType("GOLF_DISTANCE", isMetric)}
                      </th>
                      <th className='centered' scope='col'>
                        STROKE PAR
                      </th>
                      <th className='centered' scope='col'>
                        TIME PAR (MM:SS)
                      </th>
                      <th className='centered' scope='col'>
                        STR
                      </th>
                      {showHoleTimes ? (
                        <>
                          {/* <th scope='col'>TIME</th> */}
                          <th scope='col' className='centered'>
                            TIME (MM:SS)
                          </th>
                        </>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody className='table-group-divider'>
                    {selectedTee?.holes.map((item, key) => {
                      return (
                        <>
                          <tr>
                            <td className='centered'>{item?.number}</td>
                            {/* <td className='centered'>{item.runDistance}</td> */}
                            {/* <td className='centered'>{item?.transRunDistance ? +item?.transRunDistance : "ND"}</td> */}
                            {/* <td className='centered'>{item?.golfRunDistance ? +item?.golfRunDistance : "ND"}</td> */}
                            <td className='centered'>
                              {translateDistanceByType(+item.runDistance, "runDistance", isMetric)}
                            </td>
                            <td className='centered'>
                              {item?.transRunDistance
                                ? translateDistanceByType(+item?.transRunDistance, "transRunDistance", isMetric)
                                : "ND"}
                            </td>
                            <td className='centered'>
                              {item?.golfRunDistance
                                ? translateDistanceByType(+item?.golfRunDistance, "golfRunDistance", isMetric)
                                : "ND"}
                            </td>
                            <td className='centered'>
                              {item[user?.personalInfo?.parGender + "StrokePar"]
                                ? item[user?.personalInfo?.parGender + "StrokePar"]
                                : "ND"}
                            </td>
                            <td className='centered'>
                              {item[user?.personalInfo?.parGender + "TimePar"]
                                ? `${
                                    Math.floor(item[user?.personalInfo?.parGender + "TimePar"] / 60) +
                                    ":" +
                                    Math.floor(item[user?.personalInfo?.parGender + "TimePar"] % 60)
                                      .toString()
                                      .padStart(2, "0")
                                  }`
                                : "ND"}
                            </td>
                            <td className='centered'>
                              <input
                                id='roundStrokes'
                                type='number'
                                min='1'
                                max='99'
                                tabIndex={key + 1}
                                aria-describedby='roundStrokesDescr'
                                value={
                                  !isNaN(holeByHole[item?.number - 1]["strokes"])
                                    ? holeByHole[item?.number - 1]["strokes"] == null
                                      ? ""
                                      : holeByHole[item?.number - 1]["strokes"]
                                    : "ND"
                                }
                                onChange={(e) => {
                                  const updatedTempHoleData = holeByHole.map((hole, index) => {
                                    if (index === item?.number - 1 && e.target.value) {
                                      let strokesInt = parseInt(e.target.value);
                                      return {
                                        ...hole,
                                        strokes: strokesInt,
                                        strokesToPar: strokesInt - item[user?.personalInfo?.parGender + "StrokePar"] ,
                                      };
                                    }
                                    return hole;
                                  });
                                  setHoleByHole(updatedTempHoleData);
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "Delete" || e.key === "Backspace") {
                                    const updatedTempHoleData = holeByHole.map((hole, index) => {
                                      if (index === item?.number - 1) {
                                        return {
                                          ...hole,
                                          strokes: null,
                                          strokesToPar: null,
                                        };
                                      }
                                      return hole;
                                    });
                                    setHoleByHole(updatedTempHoleData);
                                  }
                                }}
                                className='centered'
                              />
                            </td>
                            {showHoleTimes ? (
                              <>
                                {/* <td className='centered'>
                                  {holeByHole[item?.number - 1]["finishTime"] &&
                                  (item?.number == 18 ? holeByHole[item?.number - 2]["startTime"] : true)
                                    ? moment
                                        .utc(
                                          moment
                                            .duration(
                                              moment(holeByHole[item?.number - 1]["finishTime"], "HH:mm:ss").diff(
                                                moment(holeByHole[item?.number - 1]["startTime"], "HH:mm:ss")
                                              )
                                            )
                                            .asMilliseconds()
                                        )
                                        .format("mm:ss")
                                    : "ND"}
                                </td> */}
                                {/* <td className="centered">{"time"}</td> */}
                                <td className='centered'>
                                  <form>
                                    {/* <input
                                      id='teeStartDate'
                                      type='time'
                                      step={2}
                                      aria-describedby='teeStartDateDescr'
                                      value={moment(holeByHole[item?.number - 1]["finishTime"], "HH:mm:ss").format(
                                        "HH:mm:ss"
                                      )}
                                      disabled={
                                        !roundStartTime
                                          ? true
                                          : item?.number == 1 || item?.number == 18
                                          ? false
                                          : !isNaN(holeByHole[item?.number - 2]["finishTime"])
                                      }
                                      onChange={(e) => setTempTime(e.target.value)}
                                      onBlur={() => validTimeCheckAndUpdate(item)}
                                    /> */}
                                    <div className='d-flex flex-row align-items-center justify-content-between'>
                                      <input
                                        id='holeMinutes'
                                        type='number'
                                        size='3'
                                        aria-describedby='roundTimeDescr'
                                        min='1'
                                        max='99'
                                        // disabled={scorecardButtonEnable}
                                        style={{ textAlign: "right" }}
                                        value={
                                          holeByHole[item?.number - 1]["minutes"] != null
                                            ? holeByHole[item?.number - 1]["minutes"]
                                            : ""
                                        }
                                        // value={10}
                                        // onChange={(e) => setHoleMinutes(e.target.value)}
                                        onChange={(e) => updateHoleTime(item, e.target.value, "minutes")}
                                        onBlur={() => validateAndSetElapsedTime(item, "minutes")}
                                      />
                                      :
                                      <input
                                        id='holeSeconds'
                                        type='number'
                                        size='2'
                                        min='0'
                                        max='59'
                                        aria-describedby='roundTimeDescr'
                                        // disabled={scorecardButtonEnable}
                                        value={
                                          holeByHole[item?.number - 1]["seconds"] != null
                                            ? holeByHole[item?.number - 1]["seconds"]?.toString().padStart(2, "0")
                                            : ""
                                        }
                                        // onChange={(e) => setHoleSeconds(e.target.value)}
                                        onChange={(e) => updateHoleTime(item, e.target.value, "seconds")}
                                        onBlur={() => validateAndSetElapsedTime(item, "seconds")}
                                      />
                                    </div>
                                  </form>
                                </td>
                              </>
                            ) : null}
                          </tr>
                          {item?.number == 9 || item.number == 18 ? (
                            <tr>
                              <td className='centered' scope='row'>
                                {item?.number == 9 ? "OUT" : "IN"}
                              </td>
                              <td className='centered'>
                                {item?.number == 9
                                  ? translateDistanceByType(+outData?.runDistance, "outRunDistance", isMetric)
                                  : translateDistanceByType(+inData?.runDistance, "inRunDistance", isMetric)}
                              </td>
                              <td className='centered'>
                                {item?.number == 9
                                  ? translateDistanceByType(+outData?.transRunDistance, "outTransRunDistance", isMetric)
                                  : translateDistanceByType(+inData?.transRunDistance, "inTransRunDistance", isMetric)}
                              </td>
                              <td className='centered'>
                                {item?.number == 9
                                  ? translateDistanceByType(+outData?.golfRunDistance, "outGolfRunDistance", isMetric)
                                  : translateDistanceByType(inData?.golfRunDistance, "inGolfRunDistance", isMetric)}
                              </td>
                              <td className='centered'>
                                {item?.number == 9
                                  ? outData[user?.personalInfo?.parGender + "StrokePar"]
                                  : inData[user?.personalInfo?.parGender + "StrokePar"]}
                              </td>
                              <td className='centered'>
                                {item?.number == 9
                                  ? `${
                                      Math.floor(outData[user?.personalInfo?.parGender + "TimePar"] / 60) +
                                      ":" +
                                      (outData[user?.personalInfo?.parGender + "TimePar"] % 60)
                                        .toString()
                                        .padStart(2, "0")
                                    }`
                                  : `${
                                      Math.floor(inData[user?.personalInfo?.parGender + "TimePar"] / 60) +
                                      ":" +
                                      (inData[user?.personalInfo?.parGender + "TimePar"] % 60)
                                        .toString()
                                        .padStart(2, "0")
                                    }`}
                              </td>
                              <td className='centered'>
                                <input
                                  id='roundStrokes'
                                  type='number'
                                  min='1'
                                  max='99'
                                  disabled
                                  aria-describedby='roundStrokesDescr'
                                  value={
                                    item?.number == 9
                                      ? totalStrokesData?.out["strokes"]
                                      : totalStrokesData?.in["strokes"]
                                  }
                                  className='centered'
                                />
                              </td>
                              {showHoleTimes ? (
                                <>
                                  {/* <td>{item?.time}</td> */}
                                  {/* <td>{item?.finishTime}</td> */}
                                  <td>
                                    <input
                                      id='holeMinutes'
                                      type='number'
                                      size='3'
                                      aria-describedby='roundTimeDescr'
                                      min='1'
                                      max='99'
                                      disabled
                                      style={{ textAlign: "right" }}
                                      value={
                                        item?.number == 9
                                          ? totalStrokesData?.out?.minutes
                                          : totalStrokesData?.in?.minutes
                                      }
                                    />
                                    :
                                    <input
                                      id='holeSeconds'
                                      type='number'
                                      size='2'
                                      min='0'
                                      max='59'
                                      disabled
                                      aria-describedby='roundTimeDescr'
                                      value={
                                        item?.number == 9
                                          ? totalStrokesData?.out?.seconds.toString().padStart(2, "0")
                                          : totalStrokesData?.in?.seconds.toString().padStart(2, "0")
                                      }
                                    />
                                  </td>
                                </>
                              ) : null}
                            </tr>
                          ) : null}
                        </>
                      );
                    })}
                    <tr>
                      <td className='centered' scope='row'>
                        {"TOTAL"}
                      </td>
                      <td className='centered' scope='row'>
                        {translateDistanceByType(+totalData?.runDistance, "totalRunDistance", isMetric)}
                      </td>
                      <td className='centered'>
                        {translateDistanceByType(+totalData?.transRunDistance, "totalTransRunDistance", isMetric)}
                      </td>
                      <td className='centered'>
                        {translateDistanceByType(+totalData?.golfRunDistance, "totalGolfRunDistance", isMetric)}
                      </td>
                      <td className='centered'>{totalData[user?.personalInfo?.parGender + "StrokePar"]}</td>
                      <td className='centered'>{`${
                        Math.floor(totalData[user?.personalInfo?.parGender + "TimePar"] / 60) +
                        ":" +
                        (totalData[user?.personalInfo?.parGender + "TimePar"] % 60).toString().padStart(2, "0")
                      }`}</td>
                      <td className='centered'>{totalStrokesData?.total["strokes"]}</td>
                      {/* <td></td> */}
                      {showHoleTimes && (
                        <td>
                          <input
                            id='holeMinutes'
                            type='number'
                            size='3'
                            aria-describedby='roundTimeDescr'
                            min='1'
                            max='99'
                            disabled
                            style={{ textAlign: "right" }}
                            value={totalStrokesData?.total?.minutes}
                          />
                          :
                          <input
                            id='holeSeconds'
                            type='number'
                            size='2'
                            min='0'
                            max='59'
                            disabled
                            aria-describedby='roundTimeDescr'
                            value={totalStrokesData?.total?.seconds.toString().padStart(2, "0")}
                          />
                        </td>
                      )}

                      {/* <td className='centered'>{}</td>
                      <td className='centered'>{}</td> */}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                className='btn btn-danger scorecard-cancel'
                data-bs-dismiss='modal'
                onClick={() => {
                  setHoleByHole([...beforeSaveRoundData]);
                  hideScorecard();
                }}
                // onClick={() => cleanUpScoreCard()}
              >
                Cancel
              </button>
              {/* <button className='btn btn-primary' data-bs-dismiss='modal' onClick={updateGolfRoundMetrics}> */}
              <button className='btn btn-primary scorecard-save'  onClick={scoreCardHandler}>
                Save Scorecard
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ScoreCardModal;
